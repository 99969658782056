export default [
  {
    key: 'zoneId',
    label: 'field.zone',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'zone',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'number',
    label: 'field.number',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'note',
    label: 'field.note',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    type: 'checkbox',
    cols: 'auto',
  },
]
